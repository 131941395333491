import React from "react";
import ClientesSwiper from "./ClientesSwiper";
import RevealHorizontal from "../UI/RevealHorizontal";

const Clientes = () => {
  return (
    <section id="clientes" className="w-full overflow-x-hidden h-fit pt-16 pb-14 bg-essential-gray">
      <div className=" px-6 text-center leading-relaxed tracking-wider text-2xl sm:text-3xl">
        <h2 className="text-black uppercase italic">
          Nuestros{" "}
          <span className=" text-essential-gray not-italic bg-dolar-green font-black px-2 py-1 shadow-[4px_4px_6px_0px_rgba(0,0,0,0.4)]">
            Clientes
          </span>
        </h2>
      </div>
      <ClientesSwiper />
        <RevealHorizontal>
          <div className='w-[80%] mx-auto flex items-center justify-center mt-14'>
            <div className='relative w-[80%]'>
              <div className='absolute left-0 w-[4px] h-[4px] bg-dolar-green top-1/2 -translate-y-1/2'></div>
              <div className='absolute right-0 w-[4px] h-[4px] bg-dolar-green top-1/2 -translate-y-1/2'></div>
              <div className='absolute w-full h-[0.5px] bg-dolar-green top-1/2 -translate-y-1/2'></div>
            </div>
          </div>
        </RevealHorizontal>
    </section>
  );
};

export default Clientes;

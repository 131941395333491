import { elementos } from '../../assets/data/trabajamos'
import Reveal from '../UI/Reveal';
import RevealHorizontal from '../UI/RevealHorizontal';
import Tarjeta from './Tarjeta';


const Trabajamos = () => {
    return (
        <section id='trabajamos' className="w-full overflow-x-hidden h-fit py-12 bg-navy-blue">
            <div className="container mx-auto px-6 text-center leading-relaxed tracking-wider text-2xl sm:text-3xl">
                <h2 className="text-essential-gray uppercase italic">
                    Como{" "}
                    <span className="text-navy-blue not-italic bg-sconce-gold font-black px-2 py-1 shadow-[4px_4px_6px_0px_rgba(0,0,0,0.4)]">
                        Trabajamos
                    </span>
                </h2>
            </div>
            <div className="lg:flex lg:flex-row-reverse full items-center lg:items-start lg:justify-evenly mt-16">
                <div className="lg:w-[50%] my-auto">
                    <p className="text-essential-gray text-sm lg:text-lg ml-12 sm:pr-6 pr-12 text-start">

                        Desde el primer contacto con nosotros, nos dedicamos a escuchar y comprender sus necesidades contables para ofrecerle la mejor asistencia posible; seguimos con un análisis exhaustivo de su caso, lo que nos permite identificar la estrategia contable óptima para su negocio. Luego, le presentamos un presupuesto detallado y claro, reflejando los costos de los servicios requeridos con total transparencia. Con su aprobación, nuestro equipo de expertos gestiona su caso con precisión y eficiencia, asegurando la resolución satisfactoria de todas las cuestiones contables. Nuestro compromiso es su completa satisfacción, esforzándonos por ofrecer un servicio que no solo cumpla con sus necesidades sino que también exceda sus expectativas, garantizando así clientes contentos y relaciones duraderas.
                    </p>
                </div>
                    <div className="lg:my-auto mt-16 lg:ml-16 grid grid-cols-1 md:grid-cols-2 justify-items-center xl:grid-cols-3 grid-rows-6 sm:grid-rows-3 lg:grid-rows-2 gap-x-8 gap-y-12">
                        {elementos.map((elemento, index) => (
                            <Reveal>
                                <Tarjeta key={index} icono={elemento.icono} texto={elemento.texto} numero={elemento.numero} />
                            </Reveal>
                        ))}
                    </div>
            </div>
            <RevealHorizontal>
                <div className='w-[80%] mx-auto flex items-center justify-center mt-14'>
                    <div className='relative w-[80%]'>
                        <div className='absolute left-0 w-[4px] h-[4px] bg-sconce-gold top-1/2 -translate-y-1/2'></div>
                        <div className='absolute right-0 w-[4px] h-[4px] bg-sconce-gold top-1/2 -translate-y-1/2'></div>
                        <div className='absolute w-full h-[0.5px] bg-sconce-gold top-1/2 -translate-y-1/2'></div>
                    </div>
                </div>
            </RevealHorizontal>

        </section>
    );
};

export default Trabajamos;
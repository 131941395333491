import Header from "./Header";
import "../../assets/styles/button.css";
import SvgComponent from "../Vectors/AnimatedSvgComponent";
import '../../assets/styles/titulo.css'
import { motion } from "framer-motion";

const Home = () => {
  const handleScrollToContact = () => {
    
    document.getElementById('contacto').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section id="Home" className="w-full relative overflow-hidden bg-navy-blue h-screen">
      <SvgComponent />
      <Header className='z-10' />
      <div className="flex flex-col h-full justify-center pt-16">
        <div className="titulo w-full flex flex-col  font-poppins  z-10 py-4 text-center">
          <h1 className="text-essential-gray tracking-wider pb-3 lg:text-7xl text-2xl sm:text-5xl uppercase">Estudio Contable</h1>
          <motion.p className="text-xl sm:text-4xl lg:text-6xl tracking-widest mt-1 font-bebas"
            animate={{
              color: ['#9c6f31', '#B2803A', '#9c6f31'], // Corrected the hex code for black
              transition: {
                duration: 1,
                repeat: Infinity,
              },
            }}
          >
            DH & ASOCS.
          </motion.p>
        </div>
        <div className="text-center flex items-center justify-center  mt-1 z-10">
          <button onClick={handleScrollToContact} className="scroll-behavior btn-animate lg:border-[3px] border-2 px-2 py-2 shadow-xl border-essential-gray text-essential-gray font-bold lg:px-6 lg:py-3 sm:active:bg-essential-gray sm:active:text-navy-blue flex items-center justify-center w-fit h-full">
            <span className="uppercase tracking-wider font-semibold lg:font-bold lg:tracking-widest text-xs sm:text-sm lg:text-lg">Contacto</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Home;
import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const Input = ({
    label,
    type,
    inputStyle,
    labelStyle,
    name,
    value,
    onChange,
    isValid,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);
    const [enteredEmail, setEmail] = useState('');
    const [isTouched, setIsTouched] = useState(false);
    const [blurEvent, setBlurEvent] = useState(false);
    const [isInvalid, setInvalid] = useState(false);
    const [enteredText, setText] = useState('');
    const [enteredTel, setTel] = useState('');
    const buttonPressed = useSelector(state => state.form.buttonPressed);
    const reset = useSelector(state => state.form.reset);

    const isValidEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    const isValidTel = (tel) =>{
        const telRegex = /^\+?\d{1,4}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/g;
        return telRegex.test(tel);
    }

    useEffect(() => {
        if (type === 'email' && isTouched && blurEvent) {
            if (!isValidEmail(enteredEmail)) {
                setInvalid(true)
                isValid(false)
            } else {
                setInvalid(false);
                isValid(true)
            }
        }

        if (type === 'text' && isTouched && blurEvent) {
            if (enteredText.trim() === "") {
                setInvalid(true)
                isValid(false)
            } else {
                setInvalid(false)
                isValid(true)
            }
        }

        if (type === 'tel' && isTouched && blurEvent) {
            if (!isValidTel(enteredTel)) {
                setInvalid(true)
                isValid(false)
            } else {
                setInvalid(false)
                isValid(true)
            }
        }

    }, [enteredEmail, isTouched, type, blurEvent, enteredText, enteredTel])



    useEffect(() => {
        if (type === 'email') {
            if (!isValidEmail(enteredEmail)) {
                setInvalid(true);
                setBlurEvent(true);
                isValid(false);
            }
        } if (type === 'text') {
            if (enteredText.trim() === '') {
                setInvalid(true);
                setBlurEvent(true);
                isValid(false);
            }
        }
        if (type === 'tel') {
            if (!isValidTel(enteredTel)) {
                setInvalid(true);
                setBlurEvent(true);
                isValid(false);
            }
        }
    }, [buttonPressed, type]);

    useEffect(() => {
        setInvalid(false);
        setBlurEvent(false);
        setIsTouched(false);
        setIsFocused(false);
        setEmail('');
        setText('');
        setTel('');
        isValid(false);
    }, [reset])

    const focusHandler = () => {
        setIsFocused(true);
        setIsTouched(true);
    };



    const changeHandler = (event) => {
        if (type === 'email') {
            setEmail(event.target.value)
        }
        if (type === 'text') {
            setText(event.target.value)
        }if (type === 'tel'){
            setTel(event.target.value)
        }
        onChange(event);
    }

    const blurHandler = () => {
        if (inputRef.current.value.trim() === "") {
            setIsFocused(false);
        } else {
            setIsFocused(true);
        }
        setBlurEvent(true);
    };

    const pyClass = isFocused ? "py-[0px]" : "py-[10px]";


    return (
        <div className="sm:mb-4 mb-2 relative mt-2 sm:mt-4 w-full">
            <label
                className={`${labelStyle}  ${isFocused ? "translate-x-[10px] translate-y-[-9px] text-[0.75em] px-[10px] bg-tricorn-black border-l-[1px] border-r-[1px] tracking-[0.25em]" : "border-none"} ${isInvalid ? "text-red-custom border-red-custom" : "text-sconce-gold border-sconce-gold"} ${pyClass}`}
            >
                {label}
            </label>
            <input
                className={`${inputStyle}  ${isInvalid ? "border-red-custom " : " border-sconce-gold"
                    }`}
                type={type}
                name={name}
                value={value}
                onChange={changeHandler}
                onFocus={focusHandler}
                onBlur={blurHandler}
                ref={inputRef}
            />
            {isInvalid && (
                <div className="absolute top-[13px] right-0 flex items-center pr-3">
                    <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="text-red-custom text-xl" // Personaliza el tamaño y color según tus necesidades
                    />
                </div>
            )}
        </div>
    );
};

export default Input;
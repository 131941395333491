import React from "react";
import Formulario from "./Formulario";
import RevealHorizontal from "../UI/RevealHorizontal";

const Contacto = () => {
  return (
    <section id='contacto' className="w-full overflow-x-hidden py-16 bg-tricorn-black">
      <div className=" px-6 text-center leading-relaxed tracking-wider text-2xl sm:text-3xl">
        <h2 className=" text-essential-gray uppercase italic">
          Contactate con{" "}
          <span className="  text-tricorn-black not-italic bg-sconce-gold font-black px-2 py-1 shadow-[4px_4px_6px_0px_rgba(0,0,0,0.4)]" >
            Nosotros
          </span>
        </h2>
      </div>
      <Formulario />
      <RevealHorizontal>
        <div className='w-[80%] mx-auto flex items-center justify-center mt-14'>
          <div className='relative w-[80%]'>
            <div className='absolute left-0 w-[4px] h-[4px] bg-sconce-gold top-1/2 -translate-y-1/2'></div>
            <div className='absolute right-0 w-[4px] h-[4px] bg-sconce-gold top-1/2 -translate-y-1/2'></div>
            <div className='absolute w-full h-[0.5px] bg-sconce-gold top-1/2 -translate-y-1/2'></div>
          </div>
        </div>
      </RevealHorizontal>
    </section>
  )
};

export default Contacto;

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Tarjeta = ({icono, texto, numero}) => {
    return (
        <article className='relative w-52 hover:scale-[1.05] transition-all ease-in-out duration-300 text-center'>
            <div className="h-36 relative z-10 flex text-center items-center bg-none  border-sconce-gold border-2 p-4">
                <p className="text-essential-gray text-base">{texto}</p>
                <div className="absolute bg-navy-blue z-20 w-[40%] top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <FontAwesomeIcon className=' text-sconce-gold text-3xl' icon={icono} />
                </div>
            </div>
            <div className="absolute z-0 w-[40%] left-0 top-0 transform -translate-x-1/2 -translate-y-1/2">
                <FontAwesomeIcon className='text-essential-gray opacity-20 text-6xl' icon={numero} />
            </div>
        </article>
    )
}

export default Tarjeta
import React from "react";
import "tailwindcss/tailwind.css";
import afip from "../../assets/images/afip-logo.webp";
import anses from "../../assets/images/Anses-logo.webp";
import arba from "../../assets/images/arba-logo-8EE2C39B19-seeklogo.com.webp";
import igj from "../../assets/images/client-img-5.png";
import ssn from "../../assets/images/client-img-6.png";
import sssalud from "../../assets/images/client-img-7.png";
import srt from "../../assets/images/client-img-8.png";
import indec from "../../assets/images/client-img-9.png";
import bcra from "../../assets/images/client-img-10.png";
import econ from "../../assets/images/client-img-11.png";
import consejo from "../../assets/images/client-img-12.png";
import mtes from "../../assets/images/client-img-13.png";
import sepyme from "../../assets/images/client-img-14.png";
import exar from "../../assets/images/client-img-15.png";
import agip from "../../assets/images/logitoagip.png";

const logos = [
  { image: afip, url: "https://www.afip.gob.ar/landing/default.asp" },
  { image: anses, url: "https://www.anses.gob.ar/" },
  { image: arba, url: "https://web.arba.gov.ar/" },
  { image: igj, url: "https://www.argentina.gob.ar/justicia/igj" },
  { image: srt, url: "https://www.argentina.gob.ar/srt" },
  { image: indec, url: "https://www.indec.gob.ar/" },
  { image: bcra, url: "https://www.bcra.gob.ar/" },
  { image: econ, url: "https://www.argentina.gob.ar/economia" },
  { image: consejo, url: "https://www.consejo.org.ar/" },
  { image: mtes, url: "https://www.argentina.gob.ar/capital-humano/trabajo" },
  { image: exar, url: "https://www.argentina.gob.ar/produccion/exportar/exportasimple" },
  { image: agip, url: "https://www.agip.gob.ar/" },
];

const Interes = () => {
  return (
    <section className="bg-white">
      <div className="container px-5 py-8 mx-auto">
        <div className="px-6 text-center leading-relaxed tracking-wider pb-2 sm:text-3xl text-2xl mb-8">
          <h2 className="text-tricorn-black uppercase italic">
            Links de{" "}
            <span className="text-white bg-dolar-green not-italic font-black px-2 py-1 shadow-[4px_4px_6px_0px_rgba(0,0,0,0.4)]">
              Interés
            </span>
          </h2>
        </div>
        <div className="flex flex-wrap my-2 justify-center">
          {logos.map((logo, index) => (
            <a
              key={index}
              href={logo.url}
              className="m-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logo.image}
                alt={`Logo ${index + 1}`}
                className="h-12 mx-auto"
              />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Interes;

import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { clientesData } from '../../assets/data/clientes';
import { Pagination , Autoplay } from 'swiper/modules';
import 'swiper/css/bundle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import "swiper/css/effect-fade"


const ClientesSwiper = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSlideChange = (swiper) => {
        setSelectedIndex(swiper.activeIndex);
    };

    return (
        <div className='w-full pt-8 font-poppins overflow-visible  items-center flex justify-center'>
            <Swiper
                modules={[Pagination, Autoplay]}
                pagination={{ clickable: true }}
                autoplay={{delay: 15000}}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                className='w-full h-fit py-3 align-middle items-center overflow-visible'
                onSlideChange={handleSlideChange}
                centeredSlides={true}
                slideToClickedSlide={true}
                allowTouchMove={true}
            >
                {clientesData.map((cliente, index) => (
                    <SwiperSlide className='pb-20 px-6 mt-6 overflow-visible' key={index}>
                        <div
                            className={` cursor-pointer flex flex-col relative px-2 pb-14 text-center rounded-sm  items-center shadow-[4px_4px_6px_0px_rgba(0,0,0,0.4)] h-full ${
                                index === selectedIndex ? 'bg-dolar-green text-essential-gray' : 'bg-transparent text-black'
                            }`}
                            style={{
                                transform: `rotate(${Math.min(Math.max((index - selectedIndex) * 5, -5), 5)}deg) ${
                                    index === selectedIndex && windowWidth >= 640 ? 'scale(1.05)' : ''
                                }`,
                                transition: 'transform 0.5s ease',
                            }}
                        >
                            <FontAwesomeIcon className='mb-2 pt-10 text-5xl' icon={faQuoteRight} />
                            <p className='mr-2 mb-2 text-sm font-light ml-2'>"{cliente.testimonio}"</p>
                            <p className='text-base font-normal'>{cliente.nombre}</p>
                            <div className='absolute bottom-2 overflow-visible z-10 translate-y-1/2'>
                            <img src={cliente.foto} alt={cliente.nombre} className='w-60 h-20 object-contain overflow-visible' />

                            </div>
                            <div className={`absolute h-full w-full border-sconce-gold border-2 rounded-sm ${
                                index === selectedIndex ? 'border-none' : 'border-sconce-gold'
                            }`}></div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default ClientesSwiper;
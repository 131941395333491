import React, { useState } from "react";

const Cards = ({ title, content, icon2 }) => {
  const [isActive, setIsActive] = useState(false);

  const handleTouch = () => {
    setIsActive(true);
    setTimeout(() => setIsActive(false), 10000);
  };

  return (
    <div
      className={`hover:scale-[1.05] transition ease-in-out  duration-500 card min-w-[250px] h-[31rem] max-w-md bg-essential-gray p-6 m-4 relative shadow-lg border-2 border-sconce-gold flex flex-col ${isActive ? "active" : ""
        }`}
      onTouchStart={handleTouch}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <h3 className="font-bold absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-0 p-4 text-xl sm:text-2xl lg:text-3xl text-sconce-gold uppercase bg-essential-gray">{title}</h3>
      <div className="mt-6 sm:mt-3 text-tricorn-black text-3xl sm:text-5xl">{icon2}</div>
      <div className="flex h-full items-center justify-center">
        <p className=" text-black text-sm md:text-xs lg:text-base">{content}</p>
      </div>

    </div>
  );
};

export default Cards;

import Input from "./Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useState, useRef, useEffect } from "react";
import { formActions } from "../../store/";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/styles/buttonContact.css"
import "../../assets/styles/span.css"

const Formulario = () => {
    const [isInvalid, setInvalid] = useState(false);
    const [enteredText, setText] = useState("");
    const [nombreValid, setNombreValid] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [telValid, setTelValid] = useState(false);
    const [blurEvent, setBlurEvent] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const inputRef = useRef(null);
    const [message, setMessage] = useState('');
    const inputStyle =
        "min-w-[185px] w-full p-[10px] outline-none border bg-tricorn-black text-white text-[1em]";
    const labelStyle =
        "absolute left-1 px-[10px] pointer-events-none duration-300 uppercase";
    const textareaStyle =
        "min-w-[185px] w-full px-3 h-64 pt-2 outline-none border bg-tricorn-black text-white text-[1em] resize-none";

    const dispatch = useDispatch();
    const buttonPressed = useSelector(state => state.form.buttonPressed);
    const reset = useSelector(state => state.form.reset);


    const focusHandler = () => {
        setIsFocused(true);
        setIsTouched(true)
    };

    const blurHandler = () => {
        if (enteredText.trim() === '') {
            setIsFocused(false);
        } else {
            setIsFocused(true)
        }
        setBlurEvent(true)
    };

    useEffect(() => {
        if (isTouched && blurEvent) {

            if (enteredText.trim() === "") {
                setInvalid(true)
            } else {
                setInvalid(false)
            }

        }
    }, [isTouched, blurEvent, enteredText])

    useEffect(() => {
        // Esta función se ejecutará cada vez que cambie el mensaje
        // Puedes realizar cualquier acción relacionada con el efecto del texto aquí

        // Reinicia la animación al cambiar el mensaje
        setAnimationClass('color-animation');

        // Limpia la clase después de la próxima renderización
        const timeout = setTimeout(() => {
            setAnimationClass('');
        }, 4000);

        return () => clearTimeout(timeout);
    }, [message]);


    const [formData, setFormData] = useState({
        nombre: "",
        email: "",
        mensaje: "",
        telefono: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'mensaje') {
            setText(value);
        }
    };

    useEffect(() => {
        if (enteredText.trim() === "") {
            setInvalid(true);
            setBlurEvent(true);
        }
    }, [buttonPressed])

    useEffect(() => {
        setInvalid(false);
        setBlurEvent(false);
        setIsFocused(false);
        setIsTouched(false);
        setText('');
    }, [reset])

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(formActions.toggleButton());
        if (!isInvalid && emailValid && nombreValid && telValid) {
            const response = await fetch("https://formspree.io/f/mvojvyla", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log(responseData);
                setFormData({
                    nombre: "",
                    email: "",
                    mensaje: "",
                    telefono: "",
                });
                setMessage('');
                setMessage('¡Formulario enviado con éxito!');
                dispatch(formActions.reset());


            } else {
                setMessage('');
                setMessage('Error. Inténtelo de nuevo.');
            }
        } else {
            setMessage('Complete los campos correctamente.');
        }
    };


    const pyClass = isFocused ? "py-[0px]" : "py-[10px]";



    return (
        <div className="w-full pt-12 mx-auto items-center flex flex-col">
            <form className="flex flex-col items-center w-[75%] sm:w-[60%] lg:w-[45%] xl:[40%]" onSubmit={handleSubmit} noValidate>
                <div className="h-[1px] mb-3 bg-sconce-gold w-[65%] self-start"></div>
                {/* linea superior*/}
                <Input
                    label="Nombre"
                    type="text"
                    inputStyle={inputStyle}
                    buttonPressed={buttonPressed}
                    labelStyle={labelStyle}
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                    isValid={setNombreValid}
                />
                <Input
                    label="Email"
                    type="email"
                    buttonPressed={buttonPressed}
                    inputStyle={inputStyle}
                    labelStyle={labelStyle}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isValid={setEmailValid}
                />
                <Input
                    label="Telefono"
                    type="tel"
                    inputStyle={inputStyle}
                    buttonPressed={buttonPressed}
                    labelStyle={labelStyle}
                    name="telefono"
                    value={formData.telefono}
                    onChange={handleChange}
                    isValid={setTelValid}
                />
                <div className="relative md:mt-4 mt-2 w-full">
                    <label
                        className={`${labelStyle}  ${isFocused ? "translate-x-[10px] translate-y-[-9px] text-[0.75em] px-[10px] bg-tricorn-black border-l-[1px] border-r-[1px] tracking-[0.25em]" : "border-none"} ${isInvalid ? "text-red-custom border-red-custom" : "text-sconce-gold border-sconce-gold"} ${pyClass}`}
                    >
                        Tu Consulta
                    </label>
                    <textarea
                        className={`${textareaStyle} ${isInvalid ? "border-red-custom" : " border-sconce-gold"} `}
                        rows="4"
                        name="mensaje"
                        value={formData.mensaje}
                        onChange={handleChange}
                        ref={inputRef}
                        onBlur={blurHandler}
                        onFocus={focusHandler}
                    ></textarea>
                    {isInvalid && (
                        <div className="absolute top-[13px] right-0 flex items-center pr-3">
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                className="text-red-custom text-xl"
                            />
                        </div>
                    )}
                </div>

                <div className="h-[0.5px] bg-sconce-gold w-[65%] self-end mt-5 mb-6"></div>
                {/* linea inferior*/}

                <div className="text-center">
                        <button className="btnc-animate border border-sconce-gold text-sconce-gold font-bold px-5 py-3" onSubmit={handleSubmit}>
                            <span className="uppercase">Enviar</span>
                        </button>
                </div>
            </form>
            <div className="h-2 mt-10 bg-tricorn-black">
                {message && (
                    <span
                        className={`text-tricorn-black text-xs sm:text-base bg-sconce-gold shadow-[4px_4px_6px_0px_rgba(0,0,0,0.4)] p-1 sm:p-2 ${animationClass}`}
                    >
                        {message}
                    </span>
                )}
            </div>

        </div>
    );
};

export default Formulario;

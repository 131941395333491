export const clientesData = [
    {
        "nombre": "Contenedores del Oeste",
        "testimonio": "Responden a cada urgencia que tenemos",
        "foto": require("../../assets/images/cliente1.png")
    },
    {
        "nombre": "Selva Mercado Natural",
        "testimonio": "Muy recomendables. Logramos mejorar nuestra liquidaciones de haberes, gracias a eso dejamos de tener conflictos con sindicatos.",
        "foto": require("../images/cliente2.jpg")
    },
    {
        "nombre": "TechGroup Buenos Aires",
        "testimonio": "Alejandro y Sebastian son dos excelentes personas, tiene la habilidad de solucionar problemas.",
        "foto": require("../../assets/images/cliente3.png")
    },
    {
        "nombre": "Vision Recruitment",
        "testimonio": "Excelente servicio. Considero que la fluidez en su comunicacion es lo mas importante.",
        "foto": require("../../assets/images/cliente4.png")
    },
    {
        "nombre": "Black Rebel",
        "testimonio": "Desde que contratamos los servicios del estudio contable, nuestra gestión financiera ha mejorado significativamente. El equipo es muy profesional y siempre está dispuesto a resolver nuestras dudas.",
        "foto": require("../../assets/images/cliente5.jpeg")
    },
    {
        "nombre": "Vaca Cay Carnes",
        "testimonio": "Trabajar con este estudio contable fue suna excelente decisión. La dedicación y conocimiento en materia contable y tributaria que nos proporcionaron fue una gran tranquilidad. ",
        "foto": require("../../assets/images/cliente6.png")
    },
    {
        "nombre": "Sol y Mar Viajes y Turismo",
        "testimonio": "El estudio contable es sido clave para mantener nuestras finanzas en orden. Su equipo es eficiente y siempre está dispuesto a ayudar. ¡Recomendados al 100%!",
        "foto": require("../../assets/images//cliente7.png")
    }
]
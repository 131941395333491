import { motion } from 'framer-motion';
import * as React from "react"
const SvgComponent = (props) => {
    return (
        <div style={{ width: '100vw', height: '100vh' }} className='absolute z-0'>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlnsSvgjs="http://svgjs.dev/svgjs"
                viewBox="0 0 1458 810" // Ajustar a las dimensiones de tu SVG original
                style={{ width: '100%', height: '100%', display: 'block' }}
                preserveAspectRatio="xMidYMid slice" // Esto hará que el SVG cubra todo el espacio disponible, potencialmente recortando partes del SVG
                {...props}
            >
                <g strokeWidth={3.5} stroke="black" fill="none" strokeLinecap="round">
                    <motion.line x1={54} y1={0} x2={0} y2={54} opacity={0.18} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={54} y1={0} x2={108} y2={54} opacity={0.51} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={108} y1={0} x2={162} y2={54} opacity={0.24} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={162} y1={0} x2={216} y2={54} opacity={0.72} />
                    <line x1={270} y1={0} x2={216} y2={54} opacity={0.76} />
                    <motion.line x1={270} y1={0} x2={324} y2={54} opacity={0.53} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={378} y1={0} x2={324} y2={54} opacity={0.66} />
                    <motion.line x1={432} y1={0} x2={378} y2={54} opacity={0.16} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={486} y1={0} x2={432} y2={54} opacity={0.67} />
                    <line x1={540} y1={0} x2={486} y2={54} opacity={0.98} />
                    <motion.line x1={540} y1={0} x2={594} y2={54} opacity={0.9} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={648} y1={0} x2={594} y2={54} opacity={0.48} />
                    <motion.line x1={702} y1={0} x2={648} y2={54} opacity={0.41} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={756} y1={0} x2={702} y2={54} opacity={0.86} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={810} y1={0} x2={756} y2={54} opacity={0.9} />
                    <motion.line x1={864} y1={0} x2={810} y2={54} opacity={0.97} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={918} y1={0} x2={864} y2={54} opacity={0.15} />
                    <motion.line x1={918} y1={0} x2={972} y2={54} opacity={0.19} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1026} y1={0} x2={972} y2={54} opacity={0.73} />
                    <line x1={1080} y1={0} x2={1026} y2={54} opacity={0.85} />
                    <line x1={1134} y1={0} x2={1080} y2={54} opacity={0.66} />
                    <line x1={1188} y1={0} x2={1134} y2={54} opacity={0.75} />
                    <motion.line x1={1242} y1={0} x2={1188} y2={54} opacity={0.56} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1296} y1={0} x2={1242} y2={54} opacity={0.53} />
                    <motion.line x1={1350} y1={0} x2={1296} y2={54} opacity={0.39} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1350} y1={0} x2={1404} y2={54} opacity={0.94} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1404} y1={0} x2={1458} y2={54} opacity={0.5} />
                    <motion.line x1={0} y1={54} x2={54} y2={108} opacity={0.79} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={54} y1={54} x2={108} y2={108} opacity={0.97} />
                    <line x1={108} y1={54} x2={162} y2={108} opacity={0.96} />
                    <motion.line x1={162} y1={54} x2={216} y2={108} opacity={0.59} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={270} y1={54} x2={216} y2={108} opacity={0.08} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={324} y1={54} x2={270} y2={108} opacity={0.19} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={378} y1={54} x2={324} y2={108} opacity={0.46} />
                    <line x1={378} y1={54} x2={432} y2={108} opacity={0.35} />
                    <line x1={432} y1={54} x2={486} y2={108} opacity={0.99} />
                    <line x1={540} y1={54} x2={486} y2={108} opacity={0.35} />
                    <motion.line x1={594} y1={54} x2={540} y2={108} opacity={0.6} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={648} y1={54} x2={594} y2={108} opacity={0.8} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={702} y1={54} x2={648} y2={108} opacity={0.14} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={756} y1={54} x2={702} y2={108} opacity={0.61} />
                    <line x1={756} y1={54} x2={810} y2={108} opacity={0.51} />
                    <line x1={810} y1={54} x2={864} y2={108} opacity={0.52} />
                    <line x1={918} y1={54} x2={864} y2={108} opacity={0.13} />
                    <motion.line x1={918} y1={54} x2={972} y2={108} opacity={0.94} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1026} y1={54} x2={972} y2={108} opacity={0.85} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1026} y1={54} x2={1080} y2={108} opacity={0.09} />
                    <motion.line x1={1134} y1={54} x2={1080} y2={108} opacity={0.29} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1188} y1={54} x2={1134} y2={108} opacity={0.71} />
                    <line x1={1188} y1={54} x2={1242} y2={108} opacity={0.32} />
                    <line x1={1296} y1={54} x2={1242} y2={108} opacity={0.17} />
                    <line x1={1350} y1={54} x2={1296} y2={108} opacity={0.13} />
                    <motion.line x1={1404} y1={54} x2={1350} y2={108} opacity={0.99} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1404} y1={54} x2={1458} y2={108} opacity={0.5} />
                    <line x1={0} y1={108} x2={54} y2={162} opacity={0.78} />
                    <line x1={54} y1={108} x2={108} y2={162} opacity={0.53} />
                    <motion.line x1={108} y1={108} x2={162} y2={162} opacity={0.82} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={216} y1={108} x2={162} y2={162} opacity={0.57} />
                    <line x1={270} y1={108} x2={216} y2={162} opacity={0.47} />
                    <line x1={270} y1={108} x2={324} y2={162} opacity={0.46} />
                    <motion.line x1={378} y1={108} x2={324} y2={162} opacity={0.62} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={432} y1={108} x2={378} y2={162} opacity={0.29} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={432} y1={108} x2={486} y2={162} opacity={0.23} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={540} y1={108} x2={486} y2={162} opacity={0.22} />
                    <motion.line x1={594} y1={108} x2={540} y2={162} opacity={0.13} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={648} y1={108} x2={594} y2={162} opacity={0.26} />
                    <motion.line x1={702} y1={108} x2={648} y2={162} opacity={0.42} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={756} y1={108} x2={702} y2={162} opacity={0.85} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={756} y1={108} x2={810} y2={162} opacity={0.11} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={864} y1={108} x2={810} y2={162} opacity={0.91} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={864} y1={108} x2={918} y2={162} opacity={0.39} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={972} y1={108} x2={918} y2={162} opacity={0.33} />
                    <motion.line x1={1026} y1={108} x2={972} y2={162} opacity={0.93} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1080} y1={108} x2={1026} y2={162} opacity={0.95} />
                    <motion.line x1={1134} y1={108} x2={1080} y2={162} opacity={0.63} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1134} y1={108} x2={1188} y2={162} opacity={0.14} />
                    <line x1={1188} y1={108} x2={1242} y2={162} opacity={0.93} />
                    <motion.line x1={1242} y1={108} x2={1296} y2={162} opacity={0.8} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1350} y1={108} x2={1296} y2={162} opacity={0.32} />
                    <motion.line x1={1350} y1={108} x2={1404} y2={162} opacity={0.38} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1404} y1={108} x2={1458} y2={162} opacity={0.78} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={0} y1={162} x2={54} y2={216} opacity={0.76} />
                    <motion.line x1={54} y1={162} x2={108} y2={216} opacity={0.98} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={108} y1={162} x2={162} y2={216} opacity={0.85} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={162} y1={162} x2={216} y2={216} opacity={0.1} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={216} y1={162} x2={270} y2={216} opacity={0.46} />
                    <line x1={324} y1={162} x2={270} y2={216} opacity={0.73} />
                    <motion.line x1={378} y1={162} x2={324} y2={216} opacity={0.92} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={378} y1={162} x2={432} y2={216} opacity={0.48} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={432} y1={162} x2={486} y2={216} opacity={0.18} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={486} y1={162} x2={540} y2={216} opacity={0.6} />
                    <line x1={540} y1={162} x2={594} y2={216} opacity={0.42} />
                    <line x1={648} y1={162} x2={594} y2={216} opacity={0.39} />
                    <line x1={648} y1={162} x2={702} y2={216} opacity={0.95} />
                    <motion.line x1={756} y1={162} x2={702} y2={216} opacity={0.55} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={810} y1={162} x2={756} y2={216} opacity={0.8} />
                    <line x1={810} y1={162} x2={864} y2={216} opacity={0.47} />
                    <motion.line x1={864} y1={162} x2={918} y2={216} opacity={0.08} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={972} y1={162} x2={918} y2={216} opacity={0.61} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={972} y1={162} x2={1026} y2={216} opacity={0.61} />
                    <line x1={1080} y1={162} x2={1026} y2={216} opacity={0.19} />
                    <line x1={1134} y1={162} x2={1080} y2={216} opacity={0.52} />
                    <line x1={1134} y1={162} x2={1188} y2={216} opacity={0.57} />
                    <motion.line x1={1188} y1={162} x2={1242} y2={216} opacity={0.56} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1242} y1={162} x2={1296} y2={216} opacity={0.97} />
                    <line x1={1296} y1={162} x2={1350} y2={216} opacity={0.58} />
                    <line x1={1404} y1={162} x2={1350} y2={216} opacity={0.87} />
                    <motion.line x1={1458} y1={162} x2={1404} y2={216} opacity={0.43} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={0} y1={216} x2={54} y2={270} opacity={0.87} />
                    <line x1={54} y1={216} x2={108} y2={270} opacity={0.89} />
                    <motion.line x1={108} y1={216} x2={162} y2={270} opacity={0.89} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={162} y1={216} x2={216} y2={270} opacity={0.76} />
                    <line x1={216} y1={216} x2={270} y2={270} opacity={0.8} />
                    <line x1={324} y1={216} x2={270} y2={270} opacity={0.85} />
                    <motion.line x1={378} y1={216} x2={324} y2={270} opacity={0.53} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={378} y1={216} x2={432} y2={270} opacity={0.52} />
                    <motion.line x1={486} y1={216} x2={432} y2={270} opacity={0.07} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={486} y1={216} x2={540} y2={270} opacity={0.28} />
                    <motion.line x1={540} y1={216} x2={594} y2={270} opacity={0.61} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={648} y1={216} x2={594} y2={270} opacity={0.41} />
                    <motion.line x1={702} y1={216} x2={648} y2={270} opacity={0.89} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={756} y1={216} x2={702} y2={270} opacity={0.46} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={756} y1={216} x2={810} y2={270} opacity={0.18} />
                    <motion.line x1={864} y1={216} x2={810} y2={270} opacity={0.27} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={864} y1={216} x2={918} y2={270} opacity={0.43} />
                    <line x1={972} y1={216} x2={918} y2={270} opacity={0.66} />
                    <motion.line x1={972} y1={216} x2={1026} y2={270} opacity={0.45} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1026} y1={216} x2={1080} y2={270} opacity={0.27} />
                    <motion.line x1={1080} y1={216} x2={1134} y2={270} opacity={0.5} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1188} y1={216} x2={1134} y2={270} opacity={0.4} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1242} y1={216} x2={1188} y2={270} opacity={0.92} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1242} y1={216} x2={1296} y2={270} opacity={0.87} />
                    <line x1={1350} y1={216} x2={1296} y2={270} opacity={0.72} />
                    <motion.line x1={1350} y1={216} x2={1404} y2={270} opacity={0.99} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1458} y1={216} x2={1404} y2={270} opacity={0.21} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={54} y1={270} x2={0} y2={324} opacity={0.59} />
                    <line x1={108} y1={270} x2={54} y2={324} opacity={0.84} />
                    <motion.line x1={162} y1={270} x2={108} y2={324} opacity={0.86} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={216} y1={270} x2={162} y2={324} opacity={0.77} />
                    <motion.line x1={216} y1={270} x2={270} y2={324} opacity={0.49} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={270} y1={270} x2={324} y2={324} opacity={0.22} />
                    <motion.line x1={378} y1={270} x2={324} y2={324} opacity={0.09} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={378} y1={270} x2={432} y2={324} opacity={0.45} />
                    <line x1={432} y1={270} x2={486} y2={324} opacity={0.32} />
                    <motion.line x1={486} y1={270} x2={540} y2={324} opacity={0.45} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={540} y1={270} x2={594} y2={324} opacity={0.36} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={594} y1={270} x2={648} y2={324} opacity={0.07} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={702} y1={270} x2={648} y2={324} opacity={0.15} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={702} y1={270} x2={756} y2={324} opacity={0.85} />
                    <line x1={810} y1={270} x2={756} y2={324} opacity={0.1} />
                    <motion.line x1={864} y1={270} x2={810} y2={324} opacity={0.08} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={918} y1={270} x2={864} y2={324} opacity={0.66} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={972} y1={270} x2={918} y2={324} opacity={0.7} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={972} y1={270} x2={1026} y2={324} opacity={0.77} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1080} y1={270} x2={1026} y2={324} opacity={0.58} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1080} y1={270} x2={1134} y2={324} opacity={0.37} />
                    <line x1={1188} y1={270} x2={1134} y2={324} opacity={0.63} />
                    <line x1={1188} y1={270} x2={1242} y2={324} opacity={0.86} />
                    <motion.line x1={1242} y1={270} x2={1296} y2={324} opacity={0.93} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1350} y1={270} x2={1296} y2={324} opacity={0.39} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1404} y1={270} x2={1350} y2={324} opacity={0.51} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1458} y1={270} x2={1404} y2={324} opacity={0.49} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={0} y1={324} x2={54} y2={378} opacity={0.79} />
                    <motion.line x1={108} y1={324} x2={54} y2={378} opacity={0.43} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={108} y1={324} x2={162} y2={378} opacity={0.47} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={216} y1={324} x2={162} y2={378} opacity={0.94} />
                    <motion.line x1={270} y1={324} x2={216} y2={378} opacity={0.22} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={270} y1={324} x2={324} y2={378} opacity={0.32} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={378} y1={324} x2={324} y2={378} opacity={0.09} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={432} y1={324} x2={378} y2={378} opacity={0.65} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={486} y1={324} x2={432} y2={378} opacity={0.57} />
                    <motion.line x1={486} y1={324} x2={540} y2={378} opacity={0.92} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={540} y1={324} x2={594} y2={378} opacity={0.63} />
                    <line x1={648} y1={324} x2={594} y2={378} opacity={0.55} />
                    <line x1={702} y1={324} x2={648} y2={378} opacity={0.6} />
                    <line x1={756} y1={324} x2={702} y2={378} opacity={0.12} />
                    <motion.line x1={756} y1={324} x2={810} y2={378} opacity={0.82} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={810} y1={324} x2={864} y2={378} opacity={0.31} />
                    <line x1={918} y1={324} x2={864} y2={378} opacity={0.25} />
                    <motion.line x1={918} y1={324} x2={972} y2={378} opacity={0.86} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={972} y1={324} x2={1026} y2={378} opacity={0.6} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1080} y1={324} x2={1026} y2={378} opacity={0.1} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1134} y1={324} x2={1080} y2={378} opacity={0.26} />
                    <line x1={1134} y1={324} x2={1188} y2={378} opacity={0.31} />
                    <line x1={1188} y1={324} x2={1242} y2={378} opacity={0.46} />
                    <line x1={1296} y1={324} x2={1242} y2={378} opacity={0.81} />
                    <line x1={1350} y1={324} x2={1296} y2={378} opacity={0.99} />
                    <line x1={1404} y1={324} x2={1350} y2={378} opacity={0.73} />
                    <line x1={1458} y1={324} x2={1404} y2={378} opacity={0.09} />
                    <motion.line x1={0} y1={378} x2={54} y2={432} opacity={0.79} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={54} y1={378} x2={108} y2={432} opacity={0.57} />
                    <line x1={108} y1={378} x2={162} y2={432} opacity={0.37} />
                    <line x1={162} y1={378} x2={216} y2={432} opacity={0.56} />
                    <line x1={216} y1={378} x2={270} y2={432} opacity={0.61} />
                    <line x1={270} y1={378} x2={324} y2={432} opacity={0.31} />
                    <motion.line x1={378} y1={378} x2={324} y2={432} opacity={0.69} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={378} y1={378} x2={432} y2={432} opacity={0.21} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={486} y1={378} x2={432} y2={432} opacity={0.93} />
                    <motion.line x1={540} y1={378} x2={486} y2={432} opacity={0.72} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={594} y1={378} x2={540} y2={432} opacity={0.48} />
                    <motion.line x1={594} y1={378} x2={648} y2={432} opacity={0.58} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={648} y1={378} x2={702} y2={432} opacity={0.6} />
                    <motion.line x1={756} y1={378} x2={702} y2={432} opacity={0.15} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={810} y1={378} x2={756} y2={432} opacity={0.6} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={810} y1={378} x2={864} y2={432} opacity={0.65} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={918} y1={378} x2={864} y2={432} opacity={0.28} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={972} y1={378} x2={918} y2={432} opacity={0.96} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={972} y1={378} x2={1026} y2={432} opacity={0.42} />
                    <motion.line x1={1080} y1={378} x2={1026} y2={432} opacity={0.28} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1080} y1={378} x2={1134} y2={432} opacity={0.96} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1134} y1={378} x2={1188} y2={432} opacity={0.56} />
                    <line x1={1242} y1={378} x2={1188} y2={432} opacity={0.46} />
                    <motion.line x1={1242} y1={378} x2={1296} y2={432} opacity={0.68} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1296} y1={378} x2={1350} y2={432} opacity={0.78} />
                    <line x1={1404} y1={378} x2={1350} y2={432} opacity={0.73} />
                    <motion.line x1={1458} y1={378} x2={1404} y2={432} opacity={0.62} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={54} y1={432} x2={0} y2={486} opacity={0.3} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={108} y1={432} x2={54} y2={486} opacity={0.46} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={108} y1={432} x2={162} y2={486} opacity={0.81} />
                    <line x1={216} y1={432} x2={162} y2={486} opacity={0.57} />
                    <motion.line x1={270} y1={432} x2={216} y2={486} opacity={0.38} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={270} y1={432} x2={324} y2={486} opacity={0.49} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={378} y1={432} x2={324} y2={486} opacity={0.96} />
                    <motion.line x1={378} y1={432} x2={432} y2={486} opacity={0.86} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={486} y1={432} x2={432} y2={486} opacity={0.78} />
                    <line x1={540} y1={432} x2={486} y2={486} opacity={0.54} />
                    <motion.line x1={540} y1={432} x2={594} y2={486} opacity={0.47} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={594} y1={432} x2={648} y2={486} opacity={0.22} />
                    <motion.line x1={648} y1={432} x2={702} y2={486} opacity={0.16} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={756} y1={432} x2={702} y2={486} opacity={0.36} />
                    <motion.line x1={756} y1={432} x2={810} y2={486} opacity={0.43} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={810} y1={432} x2={864} y2={486} opacity={0.49} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={918} y1={432} x2={864} y2={486} opacity={0.28} />
                    <line x1={918} y1={432} x2={972} y2={486} opacity={0.98} />
                    <motion.line x1={972} y1={432} x2={1026} y2={486} opacity={0.88} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1080} y1={432} x2={1026} y2={486} opacity={0.29} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1080} y1={432} x2={1134} y2={486} opacity={0.13} />
                    <line x1={1134} y1={432} x2={1188} y2={486} opacity={0.11} />
                    <line x1={1242} y1={432} x2={1188} y2={486} opacity={0.39} />
                    <line x1={1242} y1={432} x2={1296} y2={486} opacity={0.92} />
                    <motion.line x1={1296} y1={432} x2={1350} y2={486} opacity={0.92} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1350} y1={432} x2={1404} y2={486} opacity={0.48} />
                    <motion.line x1={1404} y1={432} x2={1458} y2={486} opacity={0.32} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={0} y1={486} x2={54} y2={540} opacity={0.07} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={108} y1={486} x2={54} y2={540} opacity={0.17} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={162} y1={486} x2={108} y2={540} opacity={0.61} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={162} y1={486} x2={216} y2={540} opacity={0.16} />
                    <motion.line x1={270} y1={486} x2={216} y2={540} opacity={0.26} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={270} y1={486} x2={324} y2={540} opacity={0.73} />
                    <motion.line x1={378} y1={486} x2={324} y2={540} opacity={0.69} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={432} y1={486} x2={378} y2={540} opacity={0.36} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={486} y1={486} x2={432} y2={540} opacity={0.19} />
                    <motion.line x1={486} y1={486} x2={540} y2={540} opacity={0.88} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={540} y1={486} x2={594} y2={540} opacity={0.97} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={594} y1={486} x2={648} y2={540} opacity={0.39} />
                    <line x1={702} y1={486} x2={648} y2={540} opacity={0.37} />
                    <motion.line x1={756} y1={486} x2={702} y2={540} opacity={0.67} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={810} y1={486} x2={756} y2={540} opacity={0.22} />
                    <line x1={810} y1={486} x2={864} y2={540} opacity={0.1} />
                    <motion.line x1={918} y1={486} x2={864} y2={540} opacity={0.46} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={972} y1={486} x2={918} y2={540} opacity={0.65} />
                    <line x1={972} y1={486} x2={1026} y2={540} opacity={0.26} />
                    <motion.line x1={1080} y1={486} x2={1026} y2={540} opacity={0.07} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1134} y1={486} x2={1080} y2={540} opacity={0.68} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1188} y1={486} x2={1134} y2={540} opacity={0.27} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1188} y1={486} x2={1242} y2={540} opacity={0.17} />
                    <motion.line x1={1296} y1={486} x2={1242} y2={540} opacity={0.63} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1296} y1={486} x2={1350} y2={540} opacity={0.4} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1404} y1={486} x2={1350} y2={540} opacity={0.56} />
                    <line x1={1458} y1={486} x2={1404} y2={540} opacity={0.74} />
                    <motion.line x1={0} y1={540} x2={54} y2={594} opacity={0.59} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={108} y1={540} x2={54} y2={594} opacity={1} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={108} y1={540} x2={162} y2={594} opacity={0.67} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={162} y1={540} x2={216} y2={594} opacity={0.36} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={216} y1={540} x2={270} y2={594} opacity={0.31} />
                    <line x1={324} y1={540} x2={270} y2={594} opacity={0.87} />
                    <motion.line x1={324} y1={540} x2={378} y2={594} opacity={0.65} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={378} y1={540} x2={432} y2={594} opacity={0.14} />
                    <line x1={486} y1={540} x2={432} y2={594} opacity={0.08} />
                    <line x1={486} y1={540} x2={540} y2={594} opacity={0.7} />
                    <motion.line x1={594} y1={540} x2={540} y2={594} opacity={0.11} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={648} y1={540} x2={594} y2={594} opacity={0.11} />
                    <line x1={702} y1={540} x2={648} y2={594} opacity={0.2} />
                    <line x1={756} y1={540} x2={702} y2={594} opacity={0.81} />
                    <motion.line x1={756} y1={540} x2={810} y2={594} opacity={0.88} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={810} y1={540} x2={864} y2={594} opacity={0.08} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={918} y1={540} x2={864} y2={594} opacity={0.91} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={918} y1={540} x2={972} y2={594} opacity={0.28} />
                    <line x1={1026} y1={540} x2={972} y2={594} opacity={0.28} />
                    <line x1={1080} y1={540} x2={1026} y2={594} opacity={0.59} />
                    <line x1={1080} y1={540} x2={1134} y2={594} opacity={0.64} />
                    <motion.line x1={1134} y1={540} x2={1188} y2={594} opacity={0.94} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1188} y1={540} x2={1242} y2={594} opacity={0.71} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1296} y1={540} x2={1242} y2={594} opacity={0.94} />
                    <line x1={1350} y1={540} x2={1296} y2={594} opacity={0.34} />
                    <line x1={1404} y1={540} x2={1350} y2={594} opacity={0.27} />
                    <line x1={1404} y1={540} x2={1458} y2={594} opacity={0.21} />
                    <line x1={54} y1={594} x2={0} y2={648} opacity={0.82} />
                    <line x1={54} y1={594} x2={108} y2={648} opacity={0.5} />
                    <motion.line x1={108} y1={594} x2={162} y2={648} opacity={0.6} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={162} y1={594} x2={216} y2={648} opacity={0.51} />
                    <motion.line x1={216} y1={594} x2={270} y2={648} opacity={0.11} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={270} y1={594} x2={324} y2={648} opacity={0.62} />
                    <line x1={324} y1={594} x2={378} y2={648} opacity={0.15} />
                    <motion.line x1={378} y1={594} x2={432} y2={648} opacity={0.92} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={432} y1={594} x2={486} y2={648} opacity={0.5} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={540} y1={594} x2={486} y2={648} opacity={0.82} />
                    <line x1={594} y1={594} x2={540} y2={648} opacity={0.33} />
                    <motion.line x1={594} y1={594} x2={648} y2={648} opacity={0.37} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={648} y1={594} x2={702} y2={648} opacity={0.99} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={756} y1={594} x2={702} y2={648} opacity={0.56} />
                    <line x1={810} y1={594} x2={756} y2={648} opacity={0.15} />
                    <motion.line x1={864} y1={594} x2={810} y2={648} opacity={0.95} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={864} y1={594} x2={918} y2={648} opacity={0.86} />
                    <motion.line x1={972} y1={594} x2={918} y2={648} opacity={0.59} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1026} y1={594} x2={972} y2={648} opacity={0.8} />
                    <motion.line x1={1080} y1={594} x2={1026} y2={648} opacity={0.25} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1080} y1={594} x2={1134} y2={648} opacity={0.91} />
                    <motion.line x1={1188} y1={594} x2={1134} y2={648} opacity={0.66} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1188} y1={594} x2={1242} y2={648} opacity={0.96} />
                    <line x1={1242} y1={594} x2={1296} y2={648} opacity={0.21} />
                    <motion.line x1={1350} y1={594} x2={1296} y2={648} opacity={0.84} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1404} y1={594} x2={1350} y2={648} opacity={0.88} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1404} y1={594} x2={1458} y2={648} opacity={0.54} />
                    <line x1={54} y1={648} x2={0} y2={702} opacity={0.82} />
                    <line x1={54} y1={648} x2={108} y2={702} opacity={0.77} />
                    <motion.line x1={108} y1={648} x2={162} y2={702} opacity={0.25} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={216} y1={648} x2={162} y2={702} opacity={0.53} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={216} y1={648} x2={270} y2={702} opacity={0.51} />
                    <motion.line x1={270} y1={648} x2={324} y2={702} opacity={0.3} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={324} y1={648} x2={378} y2={702} opacity={0.17} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={432} y1={648} x2={378} y2={702} opacity={0.53} />
                    <motion.line x1={486} y1={648} x2={432} y2={702} opacity={0.75} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={486} y1={648} x2={540} y2={702} opacity={0.95} />
                    <line x1={594} y1={648} x2={540} y2={702} opacity={0.83} />
                    <motion.line x1={648} y1={648} x2={594} y2={702} opacity={0.18} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={648} y1={648} x2={702} y2={702} opacity={0.36} />
                    <motion.line x1={756} y1={648} x2={702} y2={702} opacity={0.13} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={756} y1={648} x2={810} y2={702} opacity={0.22} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={810} y1={648} x2={864} y2={702} opacity={0.81} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={864} y1={648} x2={918} y2={702} opacity={0.56} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={972} y1={648} x2={918} y2={702} opacity={0.45} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={972} y1={648} x2={1026} y2={702} opacity={0.14} />
                    <motion.line x1={1026} y1={648} x2={1080} y2={702} opacity={0.51} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1080} y1={648} x2={1134} y2={702} opacity={0.5} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1188} y1={648} x2={1134} y2={702} opacity={0.1} />
                    <motion.line x1={1188} y1={648} x2={1242} y2={702} opacity={0.17} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1296} y1={648} x2={1242} y2={702} opacity={0.95} />
                    <motion.line x1={1350} y1={648} x2={1296} y2={702} opacity={0.14} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1404} y1={648} x2={1350} y2={702} opacity={0.81} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1458} y1={648} x2={1404} y2={702} opacity={0.54} />
                    <motion.line x1={0} y1={702} x2={54} y2={756} opacity={0.2} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={54} y1={702} x2={108} y2={756} opacity={0.51} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={108} y1={702} x2={162} y2={756} opacity={0.42} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={216} y1={702} x2={162} y2={756} opacity={0.68} />
                    <line x1={270} y1={702} x2={216} y2={756} opacity={0.16} />
                    <line x1={324} y1={702} x2={270} y2={756} opacity={0.94} />
                    <line x1={378} y1={702} x2={324} y2={756} opacity={0.79} />
                    <motion.line x1={432} y1={702} x2={378} y2={756} opacity={0.51} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={486} y1={702} x2={432} y2={756} opacity={0.69} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={486} y1={702} x2={540} y2={756} opacity={0.6} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={594} y1={702} x2={540} y2={756} opacity={0.33} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={648} y1={702} x2={594} y2={756} opacity={0.41} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={648} y1={702} x2={702} y2={756} opacity={0.73} />
                    <line x1={756} y1={702} x2={702} y2={756} opacity={0.44} />
                    <motion.line x1={756} y1={702} x2={810} y2={756} opacity={0.71} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={810} y1={702} x2={864} y2={756} opacity={0.79} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={864} y1={702} x2={918} y2={756} opacity={0.76} />
                    <line x1={918} y1={702} x2={972} y2={756} opacity={0.23} />
                    <motion.line x1={1026} y1={702} x2={972} y2={756} opacity={0.47} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1080} y1={702} x2={1026} y2={756} opacity={0.41} />
                    <motion.line x1={1080} y1={702} x2={1134} y2={756} opacity={0.11} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1188} y1={702} x2={1134} y2={756} opacity={0.77} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1188} y1={702} x2={1242} y2={756} opacity={0.88} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1242} y1={702} x2={1296} y2={756} opacity={0.65} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1296} y1={702} x2={1350} y2={756} opacity={0.14} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1350} y1={702} x2={1404} y2={756} opacity={0.81} />
                    <line x1={1458} y1={702} x2={1404} y2={756} opacity={0.51} />
                    <line x1={54} y1={756} x2={0} y2={810} opacity={0.45} />
                    <motion.line x1={54} y1={756} x2={108} y2={810} opacity={0.92} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={162} y1={756} x2={108} y2={810} opacity={0.74} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={216} y1={756} x2={162} y2={810} opacity={0.89} />
                    <line x1={216} y1={756} x2={270} y2={810} opacity={0.43} />
                    <motion.line x1={270} y1={756} x2={324} y2={810} opacity={0.35} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={324} y1={756} x2={378} y2={810} opacity={0.8} />
                    <motion.line x1={378} y1={756} x2={432} y2={810} opacity={0.72} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={486} y1={756} x2={432} y2={810} opacity={0.34} />
                    <line x1={486} y1={756} x2={540} y2={810} opacity={0.6} />
                    <motion.line x1={594} y1={756} x2={540} y2={810} opacity={0.66} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={648} y1={756} x2={594} y2={810} opacity={0.8} />
                    <line x1={648} y1={756} x2={702} y2={810} opacity={1} />
                    <line x1={756} y1={756} x2={702} y2={810} opacity={0.84} />
                    <line x1={756} y1={756} x2={810} y2={810} opacity={0.13} />
                    <motion.line x1={864} y1={756} x2={810} y2={810} opacity={0.67} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={864} y1={756} x2={918} y2={810} opacity={0.24} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={918} y1={756} x2={972} y2={810} opacity={0.8} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1026} y1={756} x2={972} y2={810} opacity={0.55} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1026} y1={756} x2={1080} y2={810} opacity={0.86} />
                    <motion.line x1={1080} y1={756} x2={1134} y2={810} opacity={0.8} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1188} y1={756} x2={1134} y2={810} opacity={0.25} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1242} y1={756} x2={1188} y2={810} opacity={0.61} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <line x1={1242} y1={756} x2={1296} y2={810} opacity={0.68} />
                    <motion.line x1={1350} y1={756} x2={1296} y2={810} opacity={0.94} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1350} y1={756} x2={1404} y2={810} opacity={0.17} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                    <motion.line x1={1458} y1={756} x2={1404} y2={810} opacity={0.5} initial={{ pathLength: 0 }} animate={{ pathLength: 1 }} transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }} />
                </g>
            </svg>
        </div>
    )
}
export default SvgComponent
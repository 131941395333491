import React from "react";
import Cards from "./Cards";
import RevealHorizontal from "../UI/RevealHorizontal";
import Award from "../../assets/svg/award-solid.svg";
import Book from "../../assets/svg/book-solid.svg";
import HandS from "../../assets/svg/handshake-solid.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faBook,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";

const Elegirnos = () => {
  const cardsData = [
    {
      title: "Compromiso",
      content:
        "Sabemos que la contabilidad puede ser compleja y, a veces, abrumadora. Por ello, uno de nuestros principales valores es la fluidez en la comunicación con nuestros clientes. Nos esforzamos por explicar los procesos y obligaciones de manera clara y sencilla, asegurando que siempre estén informados y cómodos con las decisiones que se toman. Nuestra meta es que sienta confianza y seguridad, sabiendo que su contabilidad está en manos expertas.",
      icon: HandS,
      icon2: <FontAwesomeIcon icon={faHandshake} className="card-icon" />,
    },
    {
      title: "Calidad",
      content:
        "Ofrecemos excelencia en servicios contables con enfoque en los detalles. Nos destacamos en calidad por nuestro compromiso con la maestría técnica y la innovación continua, lo que nos permite brindar soluciones contables destacadas que impulsan el éxito y la eficiencia financiera de nuestros clientes.",
      icon: Award,
      icon2: <FontAwesomeIcon icon={faAward} className="card-icon s" />,
    },
    {
      title: "Experiencia",
      content:
        "Nuestra firma se destaca por una década de servicio contable, marcada por la excelencia y la confianza. Contamos con un equipo de profesionales cuya experiencia y formación continua garantizan una gestión precisa y actualizada. Nuestros especialistas son la base sobre la que construimos soluciones eficaces y adaptadas a cada cliente.",
      icon: Book,
      icon2: <FontAwesomeIcon icon={faBook} className="card-icon" />,
    },

  ];

  return (
    <section id="elegirnos" className="w-full overflow-x-hidden h-fit pt-16 pb-14 bg-essential-gray">
      <div className="px-6 text-center leading-relaxed tracking-wider sm:text-3xl text-2xl">
        <h2 className="text-black uppercase italic">
          ¿Por qué{" "}
          <span className="text-essential-gray bg-sconce-gold not-italic font-black px-2 py-1 shadow-[4px_4px_6px_0px_rgba(0,0,0,0.4)]">
            Elegirnos?
          </span>
        </h2>
        <div>
          <p className="text-[14px] mt-10">
            Entendemos que la contabilidad no solo es un requisito legal, sino una herramienta esencial para el crecimiento y
            la estabilidad de su empresa. Por eso, nuestro compromiso va más allá de cumplir con las obligaciones fiscales;
            aspiramos a ser socios estratégicos de nuestros clientes, ofreciéndoles soluciones contables integrales que se
            ajusten perfectamente a sus necesidades.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 place-items-center mt-8 xl:grid-rows-1 md:grid-rows-2 grid-rows-3 justify-center">
            {cardsData.map((card, index) => (
              <div
                key={index}
                className={`${index === cardsData.length - 1 ? 'md:col-span-2' : 'md:col-span-1'} xl:col-span-1`}>
                <Cards
                  title={card.title}
                  content={card.content}
                  icon={card.icon}
                  icon2={card.icon2}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <RevealHorizontal>
        <div className="w-[80%] mx-auto flex items-center justify-center mt-14">
          <div className="relative w-[80%]">
            <div className="absolute left-0 w-[4px] h-[4px] bg-sconce-gold top-1/2 -translate-y-1/2"></div>
            <div className="absolute right-0 w-[4px] h-[4px] bg-sconce-gold top-1/2 -translate-y-1/2"></div>
            <div className="absolute w-full h-[0.5px] bg-sconce-gold top-1/2 -translate-y-1/2"></div>
          </div>
        </div>
      </RevealHorizontal>
    </section>
  );
};

export default Elegirnos;

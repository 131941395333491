import React, { useState } from "react";
import "tailwindcss/tailwind.css";

let tarjetaActivaSetter = null;

const TarjetaServicio = ({ titulo, descripcion, itemLista, imagenFondo }) => {
  const [mostrarDetalle, setMostrarDetalle] = useState(false);

  const cerrarTarjeta = () => {
    setMostrarDetalle(false);
    if (tarjetaActivaSetter === setMostrarDetalle) {
      tarjetaActivaSetter = null;
    }
  };

  const handleClick = () => {
    if (tarjetaActivaSetter && tarjetaActivaSetter !== setMostrarDetalle) {
      tarjetaActivaSetter(false);
    }

    setMostrarDetalle((current) => !current);
    tarjetaActivaSetter = setMostrarDetalle;
  };


  return (
    <div
      className="relative sm:w-80 w-[75%] max-w-[330px]  h-[420px] overflow-hidden titulo shadowCard bg-cover"
      style={{ backgroundImage: `url(${imagenFondo})` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div
        className={`absolute inset-0 p-4 flex flex-col justify-center items-center transition-opacity duration-500 ${mostrarDetalle ? "opacity-0" : "opacity-100"
          }`}
      >
        <h3 className="text-2xl font-bold text-essential-gray text-center tracking-widest">{titulo}</h3>
        <button
          className="py-2 px-4 bg-transparent text-white font-semibold border border-white text-lg mt-4"
          onClick={handleClick}
        >
          Más info
        </button>
      </div>

      <div
        onClick={cerrarTarjeta}
        className={`absolute cursor-pointer inset-0 p-4 bg-black bg-opacity-50 text-white flex flex-col items-center justify-center transition-transform duration-700 ${mostrarDetalle ? "translate-y-0" : "translate-y-full"
          }`}
      >
        <div className="align-middle h-full items-start flex flex-col">
          <div className="flex flex-col">
            <p className="text-3xl font-semibold">{titulo}</p>
            <p className="text-sm font-light mt-2">{descripcion}</p>
          </div>


          <div className="h-full justify-center items-center  w-full flex">
            <div className="flex flex-col">
              {itemLista.map((item, index) => (
                <div className="flex w-fit items-center justify-start ">
                  <div className="rounded-full mr-1 h-2 w-2 bg-sconce-gold"></div>
                  <p key={index} className="text-sm w-fit text-start">
                    {item}
                  </p>
                </div>

              ))}
            </div>
          </div>



        </div>

      </div>

      <div className="absolute bg-transparent z-10 h-12 w-12 border-l-2 border-t-2 border-l-sconce-gold border-t-sconce-gold"></div>
      <div className="absolute bg-transparent z-10 h-12 w-12 border-l-2 border-b-2 border-l-sconce-gold border-b-sconce-gold bottom-0"></div>
      <div className="absolute bg-transparent z-10 h-12 w-12 border-r-2 border-t-2 border-r-sconce-gold border-t-sconce-gold right-0"></div>
      <div className="absolute bg-transparent z-10 h-12 w-12 border-r-2 border-b-2 border-r-sconce-gold border-b-sconce-gold right-0 bottom-0"></div>
    </div>
  );
};

export default TarjetaServicio;

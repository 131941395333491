import * as React from "react"
const Burger = (props) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0)">
            <path
                d="M3 6.00098H21M3 12.001H21M3 18.001H21"
                stroke="#D9D9D9"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width={24}
                    height={24}
                    fill="white"
                    transform="translate(0 0.000976562)"
                />
            </clipPath>
        </defs>
    </svg>
)
export default Burger;

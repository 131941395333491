import React from 'react'
import logodh from "../../assets/images/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';


const Footer2 = () => {
    return (
        <footer className='bg-tricorn-black overflow-hidden'>
            <div className=' mx-4 lg:mx-32 mb-6'>
                <div className='ml-4 py-6'>
                    <a href="header" class="flex items-center">
                        <img src={logodh} class="h-8 me-3" alt="FlowBite Logo" />
                        <span class="self-center text-2xl font-semibold whitespace-nowrap text-essential-gray">
                            DH & Asocs
                        </span>
                    </a>
                </div>

                <div className='flex flex-col md:flex-row justify-between'>
                    <div className="my-2 mb-4 ml-4 pr-8 md:w-[60%] w-[100%]">
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                            Nuestra oficina
                            <FontAwesomeIcon className='ml-2 text-gray-500' icon={faLocationDot} />
                        </h2>
                        <iframe title='ubicacion' className='w-full' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3287.122839107527!2d-58.474898024297914!3d-34.52511565335126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb7798b6db2a5%3A0x785f2ad6df6ebfd0!2sAv.%20del%20Libertador%201000%2C%20B1638BEV%20Vicente%20L%C3%B3pez%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1722658287698!5m2!1ses-419!2sar" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div className='flex flex-wrap flex-row w-full justify-between md:justify-evenly mt-2 pr-4 md:pr-4 mb-4 ml-4'>


                        <div className='w-fit mr-16 mb-10 sm:mb-0 sm:mr-0'>
                            <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                                Contactos
                            </h2>
                            <ul class="text-gray-500 dark:text-gray-400 font-light">
                                <li class="mb-2 ">
                                    <a href="mailto:info@dhestudio.com.ar" className="hover:underline ">
                                        info@dhestudio.com.ar
                                    </a>
                                </li>
                                <li>
                                    <a href="https://wa.me/5491171327323" className="hover:underline">
                                        +54 9 11 7132 7323
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div class="sm:flex sm:items-center sm:justify-between">
                    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                        © 2024{" "}
                        DH Estudio Contable™

                        . Todos los derechos reservados.
                    </span>
                    <div class=" flex content-center">
                        <a href="https://www.instagram.com/estudio_dh" className="text-gray-500 text-base md:text-lg mt-[0.7px] hover:text-gray-900 dark:hover:text-white">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                </div>
                <p className='-mb-6 text-xs text-gray-400'>Powerwd by MUGENCODE™.</p>
            </div>

        </footer>
    )
}

export default Footer2
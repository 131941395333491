import React, { Fragment } from "react";
import Home from "./components/Home/Home";
import Elegirnos from "./components/Elegirnos/Elegirnos";
import Servicios from "./components/Servicios/Servicios";
import Trabajamos from "./components/Trabajamos/Trabajamos";
import Clientes from "./components/Clientes/Clientes";
import Contacto from "./components/Contacto/Contacto";
import Footer from "./components/Footer/Footer";
import { Provider } from "react-redux";
import store from "./store";
import Interes from "./components/InteresMapa/Interes";

const App = () => {
  return (
    <Fragment>
      <Home />
      <Elegirnos />
      <Servicios />
      <Trabajamos />
      <Clientes />
      <Provider store={store}>
        <Contacto />
      </Provider>
      <Interes />
      <Footer />

    </Fragment>
  );
};

export default App;

import React from "react";
import RevealHorizontal from "../UI/RevealHorizontal";
import TarjetaServicio from "./TarjetasServicio";
import gestion from "../../assets/images/network-2402637_640.jpg";
import crypto from "../../assets/images/man-3126802_640.jpg";
import payroll from "../../assets/images/stock-1863880_640.jpg";
import impositivo from "../../assets/images/money-19818_1280.jpg";

const Servicios = () => {
  const servicios = [
    {
      titulo: "Impuestos",
      descripcion: "Liquidaciones de:",
      itemLista: ["IVA", "Ingresos brutos", "Convenio multilateral", "Ganancia y bienes personales"],
      imagenFondo: impositivo,
    },
    {
      titulo: "Contabilidad y Auditoria",
      descripcion: "Emision de informacion tributaria.",
      itemLista: ["Balances", "Libros", "Auditoria"],
      imagenFondo: crypto,
    },
    {
      titulo: "Monotributo",
      descripcion:
        "Asesoramiento profesional para pequeños contribuyentes.",
      itemLista: [
        "Recategorizacion",
        "Facturacion",
        "Seguimiento",
      ],
      imagenFondo: payroll,
    },
    {
      titulo: "Liquidacion de Sueldos",
      descripcion: "",
      itemLista: [
        "Recibo de haberes",
        "Cargas sociales",
        "Sindicatos",
      ],
      imagenFondo: gestion,
    },
  ];

  return (
    <section
      id="servicios"
      className="w-full overflow-x-hidden h-fit pt-16 pb-14 bg-navy-blue"
    >
      <div className="px-6 text-center leading-relaxed tracking-wider sm:text-3xl text-2xl">
        <h2 className="text-white uppercase italic">
          Nuestros{" "}
          <span className="text-navy-blue bg-sconce-gold not-italic font-black px-2 py-1 shadow-[4px_4px_6px_0px_rgba(0,0,0,0.4)]">
            Servicios
          </span>
        </h2>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mx-2 xl:grid-cols-4 gap-y-16 place-items-center mt-12 md:px-32 xl:px-0">
        {servicios.map((servicio, index) => (
          <div key={index} className="mb-4 justify-center flex w-full md:mb-0">
            <TarjetaServicio
              titulo={servicio.titulo}
              descripcion={servicio.descripcion}
              itemLista={servicio.itemLista}
              imagenFondo={servicio.imagenFondo}
            />
          </div>
        ))}
      </div>
      <RevealHorizontal>
        <div className="w-[80%] mx-auto flex items-center justify-center mt-14">
          <div className="relative w-[80%]">
            <div className="absolute left-0 w-[4px] h-[4px] bg-sconce-gold top-1/2 -translate-y-1/2"></div>
            <div className="absolute right-0 w-[4px] h-[4px] bg-sconce-gold top-1/2 -translate-y-1/2"></div>
            <div className="absolute w-full h-[0.5px] bg-sconce-gold top-1/2 -translate-y-1/2"></div>
          </div>
        </div>
      </RevealHorizontal>
    </section>
  );
};

export default Servicios;

import { useState, useEffect } from 'react';
import logo from '../../assets/images/logo.png';
import logo2 from '../../assets/images/logo2.png';
import Burger from './Burger';
import { AnimatePresence, motion } from 'framer-motion';

const Header = () => {
    const [open, setOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            // Adjust the scroll position to account for the header height
            const yOffset = -document.getElementById('header').offsetHeight;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        } else {
            console.error(`Element with id ${id} not found.`);
        }
    };

    const toggleMenu = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const navItems = [
        { title: 'Por qué elegirnos', id: 'elegirnos' },
        { title: 'Nuestros servicios', id: 'servicios' },
        { title: 'Cómo trabajamos', id: 'trabajamos' },
        { title: 'Nuestros clientes', id: 'clientes' },
        { title: 'Contacto', id: 'contacto' },
    ];

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [open]);

    useEffect(() => {
        const handleScrollEvent = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScrollEvent);

        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);

    const menuAnimations = {
        initial: {
            scaleY: 0,
        },
        animate: {
            scaleY: 1,
            transition: {
                duration: 0.5,
                ease: [0.12, 0, 0.39, 0],
            },
        },
        exit: {
            scaleY: 0,
            transition: {
                delay: 0.5,
                duration: 0.5,
                ease: [0.12, 0, 0.39, 0],
            },
        },
    };

    const linksAnimations = {
        initial: {
            y: '30vh',
            transition: {
                duration: 0.5,
                ease: [0.37, 0, 0.63, 1],
            },
        },
        open: {
            y: 0,
            transition: {
                duration: 0.7,
                ease: [0, 0.55, 0.45, 1],
            },
        },
    };

    const parentAnimation = {
        initial: {
            transition: {
                staggerChildren: 0.1,
                staggerDirection: -1,
            },
        },
        open: {
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.1,
            },
        },
    };

    return (
        <header
            id='header'
            className={`w-full fixed top-0 bg-navy-blue border-b-2 h-fit border-b-sconce-gold z-50 transition-all duration-300 ${
                scrolled ? 'py-2' : 'py-4'
            }`}
        >
            <nav className='flex w-full px-4 justify-between items-center'>
                <div className='flex ml-4 items-center'>
                    <img
                        src={logo}
                        alt='logo'
                        className={`transition-all duration-300 cursor-pointer ${
                            scrolled ? 'w-12 h-12' : 'w-16 h-16'
                        }`}
                        onClick={() => handleScroll('Home')}
                    />
                    <img
                        src={logo2}
                        alt='logo2'
                        className={`ml-4 hidden xl:block transition-all duration-300 cursor-pointer ${
                            scrolled ? 'w-20 h-6' : 'w-24 h-8'
                        }`}
                        onClick={() => handleScroll('Home')}
                    />
                </div>
                <ul className='justify-center hidden overflow-hidden xl:flex xl:items-center xl:justify-center mr-8'>
                    {navItems.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => handleScroll(item.id)}
                            className={`${
                                scrolled ? 'text-sm' : 'text-base'
                            } text-essential-gray pr-4 hover:text-sconce-gold transition-all duration-300 ease-in-out cursor-pointer tracking-wider uppercase ml-4 mb-2 lg:mb-0 ${
                                index !== navItems.length - 1
                                    ? 'border-r-2 border-r-sconce-gold hover:border-[#B2803A]'
                                    : ''
                            }`}
                        >
                            {item.title}
                        </li>
                    ))}
                </ul>
                <button
                    onClick={toggleMenu}
                    className='xl:hidden text-xl text-essential-gray w-14 h-8 mt-1'
                >
                    <Burger />
                </button>
                <AnimatePresence>
                    {open && (
                        <motion.div
                            variants={menuAnimations}
                            initial='initial'
                            animate='animate'
                            exit='exit'
                            className='fixed left-0 top-0 w-full origin-top h-full bg-essential-gray text-tricorn-black p-10'
                        >
                            <nav className='flex flex-col h-full'>
                                <div className='flex mb-4 justify-between items-center align-middle'>
                                    <div className='flex align-middle items-center'>
                                        <div className='bg-tricorn-black rounded-full sm:w-4 w-3 sm:h-4 h-3'></div>
                                        <p className='text-base h-full sm:text-2xl ml-2 sm:ml-4 tracking-widest mt-1 font-bebas'>
                                            DH & ASOCS.
                                        </p>
                                    </div>
                                    <p
                                        onClick={toggleMenu}
                                        className='cursor-pointer align-middle h-fit text-tricorn-black text-sm sm:text-base'
                                    >
                                        Cerrar
                                    </p>
                                </div>
                                <motion.ul
                                    variants={parentAnimation}
                                    initial='initial'
                                    animate='open'
                                    exit='initial'
                                    className='flex flex-col h-full justify-center items-center'
                                >
                                    {navItems.map((item, index) => (
                                        <div key={index} className='overflow-hidden'>
                                            <motion.li
                                                variants={linksAnimations}
                                                className='font-lora uppercase border-b border-b-sconce-gold text-sm sm:text-3xl font-semibold tracking-widest cursor-pointer my-4'
                                                onClick={() => {
                                                    handleScroll(item.id);
                                                    toggleMenu();
                                                }}
                                            >
                                                {item.title}
                                            </motion.li>
                                        </div>
                                    ))}
                                </motion.ul>
                            </nav>
                        </motion.div>
                    )}
                </AnimatePresence>
            </nav>
        </header>
    );
};

export default Header;
